import React, { useState, useEffect } from "react";
import axios from 'axios';
import { Auth } from "aws-amplify";
import { access_token, URL } from "../aws-token" 
import MUIDataTable from "mui-datatables";
import { createMuiTheme, MuiThemeProvider, withStyles } from '@material-ui/core/styles';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';

// Orders Component: Displays table of all orders from all customers

function Orders() {
  
  const [customers, setCustomers] = useState([0])
  const [orders, setOrders] = useState([0])
  const [open, setOpen] = useState(true);
  const [data, setData] = useState([])

  useEffect(getCustomers, [])
  useEffect(getOrders, [customers])
  useEffect(mergeCustomerOrder, [orders])

  function getCustomers() {
    Auth.currentSession().then(data => data.idToken.jwtToken)
    .then(auth_token => {
      axios.get(URL + '/customers' , {
        headers: {
          'x-api-key': access_token,
          'Authorization': auth_token
        }
      })
      .then(function (response) {
        // console.log(response)
        const dbCustomers = response.data.Items
        setCustomers(dbCustomers)
      })
      .catch(function (error) {
        console.log(error);
      });
    })
  }

  function getOrders() {
    Auth.currentSession().then(data => data.idToken.jwtToken)
    .then(auth_token => {
      axios.get(URL + '/orders', {
        headers: {
          'x-api-key': access_token,
          'Authorization': auth_token
        }
      })
      .then(function (response) {
        const dbOrders = response.data.Items
        setOrders(dbOrders)
        setOpen(false)
      })
      .catch(function (error) {
        console.log(error);
      });
    })
  };

  function mergeCustomerOrder() {
    let customerIDs = customers.map((cusItem) => {
      return cusItem['customer_id']
    })
    let mergedListOfDict = []
    for (let orderItem of orders) {
      if (customerIDs.includes(orderItem.customer_id)) {
        let customerDict = customers[customerIDs.indexOf(orderItem.customer_id)]
        let ordCusDict = Object.assign({}, customerDict, orderItem)
        mergedListOfDict.push(ordCusDict)
      }
    }
    
    if (!mergedListOfDict[0] || Object.keys(mergedListOfDict[0]).length == 0) {
      return
    } else {
      let modified_orders = [];
      for (let i in mergedListOfDict) {
        let temp_obj = {};
        temp_obj["order_id"] = mergedListOfDict[i].order_id
        temp_obj["customer_id"] = mergedListOfDict[i].customer_id
        temp_obj["org"] = mergedListOfDict[i].cus_org_name
        temp_obj["name"] = mergedListOfDict[i].cus_contact.c_name
        temp_obj["status"] = mergedListOfDict[i].order_status
        temp_obj["urls"] = mergedListOfDict[i].order_size
        temp_obj["date_created"] = String(mergedListOfDict[i].ord_creation_date).slice(0, 10)
        temp_obj["last_updated"] = String(mergedListOfDict[i].ord_lastupdate_date).slice(0, 10)
        temp_obj["urls_name"] = mergedListOfDict[i].urls
        modified_orders.push(Object.values(temp_obj))
      }
      setData(modified_orders)
    }
  }

  const getMuiTheme = () => createMuiTheme({
    overrides: {
      MUIDataTableBodyRow: {
        root: {
          '&:nth-child(odd)': { 
            backgroundColor: 'whitesmoke'
          }
        }
      },
      MUIDataTableToolbar: {
        root: {
          backgroundColor: "#00b060",
          color: "white",
        },
       icon :{
          color:"white"
        }
      },
    }
  })

  const columns = [
    {
      name: "Order ID",
      options: {
        filter: true,
        sort: true,
        SortDirection: "desc",
      }
    },
    "Customer ID", "Customer", "Main Contact", "Status", "#URLs", "Date Created", "Date Updated"
  ];


  const options = {
    selectableRows: "none",
    sortOrder: {
      name: "Order ID",
      direction: "desc"
    },
    onRowClick: (rowData) => {
      window.location.href = `/#/profile/${rowData[1]}`;
    }
  };

  const orders_table = (
    <div className="container">
      <div>
        <MuiThemeProvider theme={getMuiTheme()}>
          <MUIDataTable
            title={"Orders"}
            data={data}
            columns={columns}
            options={options}
          />
          </MuiThemeProvider>
      </div>
    </div>
  )
  
  return (
    <>
    <Backdrop style={{zIndex:"999", color:"white"}} open={open}>
      <CircularProgress color="inherit" />
    </Backdrop>

    <div style={{marginTop:"20px", paddingBottom:"100px"}}>
    <h3 className="header">Orders</h3>
    {orders_table}
    </div>
    </>

  );
}

export default Orders;