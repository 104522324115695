import React, { useState, useEffect } from "react";
import axios from 'axios';
import Order from './Order'
import { Link } from "react-router-dom";
import { Auth } from "aws-amplify";
import { access_token, URL } from "../aws-token" 
import "../styles/Profile.css";
import { TextField, Button } from '@material-ui/core';
import PublishIcon from '@material-ui/icons/Publish';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import EditIcon from '@material-ui/icons/Edit';

// Profile Component: Show a single customer's information and orders contained in the Order Component
// Create Order function
// Links to Edit Customer

function Profile(props) {
  let customer_id = parseInt(props.location.pathname.replace( /^\D+/g, ''))

  const [customer, setCustomer] = useState([]);
  const [numUrls, setNumUrls] = useState(256);
  const [open, setOpen] = useState(false);

  useEffect(getCustomers, [])

  function getCustomers () {
    Auth.currentSession().then(data => data.idToken.jwtToken)
    .then(auth_token => {
      axios.get(URL + '/customers/' + customer_id, {
        headers: {
          'x-api-key': access_token,
          'Authorization': auth_token
        }
      })  
      .then(function (response) {
        setCustomer([response.data.Item])
        }
      )
      .catch(function (error) {
        console.log(error);
        window.location.replace("/customers");
      });
    })
  }

  function postOrder(){
    const confirmOrder = window.confirm(`Generate ${numUrls} URLs?`)
    if (confirmOrder == true) {
      setOpen(true)
      Auth.currentSession().then(data => data.idToken.jwtToken)
      .then(auth_token => {
        axios.post(URL + '/orders', 
          {
            customer_id: customer_id,
            order_size: numUrls,
          },{
            headers: {
              'x-api-key': access_token,
              'Authorization': auth_token
            }
          })
        .then(function (response) {
          // console.log(response);
          window.location.reload();
        })
        .catch(function (error) {
          console.log(error);
        });
      })
    }
  }

  const customers_list = customer.map((customer, index) => (
    <>
    <div className="container-fluid" key={index}>
      <div className="row" key={index}>

        <div className="col-md-3 cus_details mg-20 profile-sidebar" style={{maxWidth:"380px", marginBottom:"20px"}}>
        
          <div className="row">
            <p className="font-weight-bold profile-header">Customer Details</p>
            <div style={{width:"fit-content", marginLeft:"auto", marginRight:"20px", marginTop:"-5px", zIndex:"100"}}>
              <Link to={{pathname: `/customer/edit/${customer.customer_id}`}}><div style={{width:"20px"}}><EditIcon style={{color:"#00b060"}}/></div></Link>
            </div>
          </div>
          <hr className="hr-line" />

          <div className="row">
            <div className="col-md-4">
              <p className="profile-info">ID:</p>
            </div>
            <div className="col-md-6 profile-value">
              {customer.customer_id ? customer.customer_id : "N/A"}
            </div>
          </div>
          <div className="row">
            <div className="col-md-4">
              <p className="profile-info">Customer:</p>
            </div>
            <div className="col-md-6 profile-value">
              {customer.cus_org_name ? customer.cus_org_name : "N/A"}
            </div>
          </div>
          {/* <div className="row">
            <div className="col-md-4">
              <p className="profile-info">Status:</p>
            </div>
            <div className="col-md-6 profile-value">
              {customer.cus_status ? customer.cus_status : "N/A"}
            </div>
          </div> */}
          <div className="row">
            <div className="col-md-4">
              <p className="profile-info">Template:</p>
            </div>
            <div className="col-md-6 profile-value">
              {customer.cus_design ? customer.cus_design : "N/A"}
            </div>
          </div>
         
          <div className="row">
              <p className="font-weight-bold profile-header">Contact</p>
          </div>
          <hr className="hr-line" />

          <div className="row">
            <div className="col-md-4">
              <p className="profile-info">Name:</p>
            </div>
            <div className="col-md-6 profile-value">
              {customer.cus_contact.c_name ? customer.cus_contact.c_name : "N/A"}
            </div>
          </div>
          <div className="row">
            <div className="col-md-4">
              <p className="profile-info">Email:</p>
            </div>
            <div className="col-md-6 profile-value">
              {customer.cus_contact.c_email ? customer.cus_contact.c_email : "N/A"}
            </div>
          </div>
          <div className="row">
            <div className="col-md-4">
              <p className="profile-info">Phone:</p>
            </div>
            <div className="col-md-6 profile-value">
              {customer.cus_contact.c_phone ? customer.cus_contact.c_phone : "N/A"}
            </div>
          </div>

          <div className="row">
            <p className="font-weight-bold profile-header">Shipping</p>
          </div>
          <hr className="hr-line" />

          <div className="row">
            <div className="col-md-4">
              <p className="profile-info">Address:</p>
            </div>
            <div className="col-md-6 profile-value">
              {customer.cus_shipping.address ? customer.cus_shipping.address : "N/A"}
            </div>
          </div>
          <div className="row">
            <div className="col-md-4">
              <p className="profile-info">City:</p>
            </div>
            <div className="col-md-6 profile-value">
              {customer.cus_shipping.city ? customer.cus_shipping.city : "N/A"}
            </div>
          </div>
          <div className="row">
            <div className="col-md-4">
              <p className="profile-info">Prov/State:</p>
            </div>
            <div className="col-md-6 profile-value">
              {customer.cus_shipping.province ? customer.cus_shipping.province : "N/A"}
            </div>
          </div>
          <div className="row">
            <div className="col-md-4">
              <p className="profile-info">Postal Code:</p>
            </div>
            <div className="col-md-6 profile-value">
              {customer.cus_shipping.post ? customer.cus_shipping.post : "N/A"}
            </div>
          </div>
          <div className="row">
            <div className="col-md-4">
              <p className="profile-info">Country</p>
            </div>
            <div className="col-md-6 profile-value">
              {customer.cus_shipping.country ? customer.cus_shipping.country : "N/A"}
            </div>
          </div>

          <div className="row">
              <p className="font-weight-bold profile-header">Partner Details</p>
          </div>
          <hr className="hr-line" />

          {/* <div className="row">
            <div className="col-md-4">
              <p className="profile-info">Partner ID:</p>
            </div>
            <div className="col-md-6 profile-value">
              {customer.partner_id ? customer.partner_id : "N/A"}
            </div>
          </div> */}
          <div className="row">
            <div className="col-md-4">
              <p className="profile-info">Name:</p>
            </div>
            <div className="col-md-6 profile-value">
              {customer.partner_contact.p_name ? customer.partner_contact.p_name : "N/A"}
            </div>
          </div>
          <div className="row">
            <div className="col-md-4">
              <p className="profile-info">Email:</p>
            </div>
            <div className="col-md-6 profile-value">
              {customer.partner_contact.p_email ? customer.partner_contact.p_email : "N/A"}
            </div>
          </div>
          <div className="row">
            <div className="col-md-4">
              <p className="profile-info">Phone:</p>
            </div>
            <div className="col-md-6 profile-value">
              {customer.partner_contact.p_phone ? customer.partner_contact.p_phone : "N/A"}
            </div>
          </div>

          <div className="row">
              <p className="font-weight-bold profile-header">Sales Contact</p>
          </div>
          <hr className="hr-line" />

          <div className="row">
            <div className="col-md-4">
              <p className="profile-info">Name:</p>
            </div>
            <div className="col-md-6 profile-value">
              {customer.sales_contact.s_name ? customer.sales_contact.s_name : "N/A"}
            </div>
          </div>
          <div className="row">
            <div className="col-md-4">
              <p className="profile-info">Email:</p>
            </div>
            <div className="col-md-6 profile-value">
              {customer.sales_contact.s_email ? customer.sales_contact.s_email : "N/A"}
            </div>
          </div>
          <div className="row">
            <div className="col-md-4">
              <p className="profile-info">Phone:</p>
            </div>
            <div className="col-md-6 profile-value">
              {customer.sales_contact.s_phone ? customer.sales_contact.s_phone : "N/A"}
            </div>
          </div>

        </div>

        <div className="col-md-9 order_div" key={index}>
          <Backdrop id="backdrop" open={open}>
            <CircularProgress color="inherit" />
          </Backdrop>
          <div className="row">
            <div className="container-fluid">
            <h3 className="header-sub" style={{marginBottom:"-10px"}}>Create Order</h3>
                <div style={{textAlign:"center", marginBottom:"20px"}}> 
                  <TextField type="number" onChange={(e)=>{setNumUrls(e.target.value)}} label="Quantity of URLs" defaultValue="256" variant="outlined" inputProps={{
                    style: {
                      height: "45px",
                      // width: "100px",
                      padding: '0 14px',
                    },
                  }}/>
                  <Button onClick={postOrder} color="primary" variant="contained" style={{height:"45px", backgroundColor:"#00B060", color:"white"}}>Create Order</Button> 
                </div>
                <h3 className="header-sub" style={{marginBottom:"-10px"}}>List of Orders</h3>
              <Order customer_id={customer_id} customer={customer.cus_org_name}/>
            </div>
          </div>
        </div>

      </div>
          
    </div>
    </>
    ));

  return (
      <div style={{marginTop:"18px"}}>
        {customers_list}
      </div>
  );
}

export default Profile;