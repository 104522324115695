/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "ca-central-1",
    "aws_cognito_region": "ca-central-1",
    "aws_user_pools_id": "ca-central-1_P1RXRZMXa",
    "aws_user_pools_web_client_id": "4q3iae605c23g9ajlq5ifga874",
    "oauth": {}
};


export default awsmobile;
